import React from "react"
import { PageProps, graphql } from "gatsby"
import { WindowLocation } from "@reach/router"

import { Frontmatter } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import BlogCard from "Components/BlogCard"
import SEO from "Components/core/SEO"

interface BlogHomeQueryData {
  frontmatter: Frontmatter
  fileAbsolutePath: string
}

interface BlogHomeProps {
  location: WindowLocation
  blogPosts: [BlogHomeQueryData]
}

export const BlogHome: React.FC<BlogHomeProps> = ({ location, blogPosts }) => {
  const categories = blogPosts
    .map(({ frontmatter: { category } }) => category)
    .filter((category, i, self) => self.indexOf(category) === i)

  const postsByCategory = categories.map(postCategory =>
    blogPosts.filter(
      ({ frontmatter: { category } }) => postCategory === category
    )
  )

  return (
    <StandardLayout location={location}>
      <SEO title="Blog" description="Liam writes about the development and games in his blog." />
      <h1>Blogs</h1>
      <p>
        Here you can find my published writing, I hope you enjoy, and feel free
        to reach out to me if would like me to write about anything else.
      </p>
      {categories.map((category, categoryIndex) => {
        const categoryPosts = postsByCategory[categoryIndex]
        return (
          <React.Fragment key={`${category}-key`}>
            <h2>{category} Posts</h2>
            <ul>
              {categoryPosts.map(
                (
                  { frontmatter: { title, published }, fileAbsolutePath },
                  postIndex
                ) => (
                  <li key={`${category}-post-${postIndex}-key`}>
                    <BlogCard
                      title={title}
                      published={published}
                      fileAbsolutePath={fileAbsolutePath}
                    />
                  </li>
                )
              )}
            </ul>
          </React.Fragment>
        )
      })}
    </StandardLayout>
  )
}

const BlogHomePage: React.FC<PageProps<{
  allMdx: { nodes: [BlogHomeQueryData] }
}>> = ({ location, data }) => (
  <>
    <BlogHome location={location} blogPosts={data.allMdx.nodes} />
  </>
)

export const query = graphql`
  query MyQuery {
    allMdx {
      nodes {
        frontmatter {
          category
          date
          description
          published
          tags
          title
        }
        fileAbsolutePath
      }
    }
  }
`

export default BlogHomePage
