import React from "react"

interface BlogCardProps {
  title: string
  fileAbsolutePath: string
  published: boolean
  data?: string
  description?: string
  tags?: string
}

function getBlogUrlFromPath(path: string): string {
  const pathAsArray = path.split("/")
  const urlStartIndex = pathAsArray.indexOf("blog")
  const urlPath = pathAsArray
    .slice(urlStartIndex)
    .join("/")
    .replace(".mdx", "")
  const url = `/${urlPath}`

  return url
}

const BlogCard: React.FC<BlogCardProps> = ({
  title,
  fileAbsolutePath,
  published,
}) => {
  const url = getBlogUrlFromPath(fileAbsolutePath)

  return published && <a href={url}>{title}</a>
}

export default BlogCard
